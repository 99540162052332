<template>
  <div class="lux-container">
    <div class="search-area">
      {{showLang('com.site.affiliated')}}：
      <Select v-model="form.stationId" number style="width: 400px; margin-right: 10px">
        <template v-for="(item, idx) in stations">
          <Option :value="item.id" :key="idx">{{item.name}}</Option>
        </template>
      </Select>
      {{showLang('com.date.start')}}：
      <DatePicker v-model="form.start" type="datetime" :placeholder="showLang('com.date.start')" style="width: 180px; margin-right: 10px;"></DatePicker>
      {{showLang('com.date.end')}}：
      <DatePicker v-model="form.end" type="datetime" :placeholder="showLang('com.date.end')" style="width: 180px; margin-right: 10px;"></DatePicker>
      <AuthButton opCode="las" size="default" type="info" @click="getData" style="margin-right: 10px;">{{showLang('com.op.query')}}</AuthButton>
      <AuthButton opCode="lae" size="default" type="success" @click="exportData">{{showLang('com.op.export')}}</AuthButton>
    </div>
    <div class="data-area" ref="table">
      <u-table :data="tabData" style="width: 100%;" row-key="id" use-virtual border :height="tabHeight" :page-sizes="[15, 50, 100, 200, 500]" :pagination-show="true" :total="count" :page-size="form.size" :current-page="form.index" @handlePageSize="handlePageSize" :empty-text="showLang('com.data.no')">
        <u-table-column type="index" width="50"></u-table-column>
        <u-table-column prop="createTime" :label="showLang('lux.tab.Trigger.time')" sortable></u-table-column>
        <u-table-column prop="stationName" :label="showLang('lux.tab.Trigger.Site')" sortable></u-table-column>
        <u-table-column prop="lux" :label="showLang('lux.tab.Trigger.brightness')" sortable width="110"></u-table-column>
        <u-table-column prop="targetStationName" :label="showLang('lux.tab.target.site')" sortable></u-table-column>
        <u-table-column prop="paramName" :label="showLang('lux.tab.control.target')" sortable></u-table-column>
        <u-table-column prop="channel" :label="showLang('lux.tab.control.channel')" sortable></u-table-column>
        <u-table-column prop="open" :label="showLang('lux.tab.control.action')" sortable>
          <template slot-scope="scope">
            {{getActionName(scope)}}
          </template>
        </u-table-column>
        <u-table-column prop="message" :label="showLang('lux.tab.control.result')" sortable></u-table-column>
      </u-table>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
// import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'LuxSearchIndex',
  props: {
    stationId: {
      type: Number,
      default: 0
    }
  },
  components: {
  },
  data() {
    return {
      stations: [],
      tabHeight: 300,
      tabData: [],
      count: 0,
      loading: false,
      form: {
        stationId: 0,
        start: "",
        end: "",
        index: 1,
        size: 15,
      }
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('group', ['selectedNode']),
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('common', ['weeks', 'productCodes']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    stationId(){
      this.form.stationId = this.stationId;
      this.getData();
    }
  },
  mounted: function () {
    let now = new Date();
    now.setDate(now.getDate() + 1);
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 2);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(this.setTableHeight, 200);
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    // exportData(){
    //   // let dataArr=JSON.parse(JSON.stringify(this.tabData));
    //   exportXlsx(this.tabData, {idx: '序号', time: '采集时间', l: '光照度', t: '温度', h: '湿度'}, "光照度历史数据");
    // },
    exportData() {
      if (this.form.stationId <= 0) return;
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = { stationId: this.form.stationId, start: this.form.start, end: this.form.end };
      this.$axios.request({
        url: `//${this.domains.trans}/station/lux/ExportAction`,
        method: 'post',
        data: params,
        responseType: 'blob'
      }).then(res => {
        // console.log('export', res)
        // const data = res.data;
        let url = window.URL.createObjectURL(res);
        var a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `${this.showLang('lux.action.record')}.xls`
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url)
      })
    },
    getActionName: function (params) {
      let row = params.row;
      if (row.paramType == 3) { //控制配电通道
        if (row.arguments.open) {
          return this.showLang('lux.energize.to')+`${row.arguments.expire}`;
        } else {
          return this.showLang('lux.outage.to')+`${row.arguments.expire}`;
        }
      } else if (row.paramType == 199) {//控制单灯
        let now = new Date(row.createTime);
        now.setMinutes(now.getMinutes() + row.arguments.expire);
        return this.showLang('lux.Dimming.brightness')+`：${row.arguments.bright1}，${this.showLang('com.lighting.oc')}：${row.arguments.color1}，-${now.format('yyyy-MM-dd HH:mm:ss')}`
      }
      return '-'
    },
    handlePageSize: function (params) {
      // console.log('handle page size', params);
      this.form.size = params.size;
      this.form.index = params.page;
      this.getData();
    },
    getData: function () {
      // if (this.form.stationId <= 0) return;
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = { stationId: this.form.stationId, start: this.form.start, end: this.form.end, index: this.form.index, size: this.form.size };
      // this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryAction`, params).then(res => {
        // this.loading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'tabData', res.data.list);
          this.count = res.data.count;
        }
      });
    },
    getList: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryDevice`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'stations', res.data);
          if (this.stationId) {
            this.form.stationId = this.stationId;
          } else if (res.data.length > 0) {
            this.form.stationId = res.data[0].id;
          }
          // this.getData();
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight;
    },
    switchMenu: function (menu) {
      this.$store.commit('auth/setStationMenu', menu);
    },
    showDeviceDetail: function (dev) {
      this.$refs.tree.selectNodeByData(dev);
    },
  }
}
</script>
<style scoped>
.lux-container {
  background: white;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  /* border: solid 1px red; */
  flex-direction: column;
}
.search-area {
  padding: 10px;
  height: 40px;
  flex: none;
  /* border: solid 1px red; */
  display: flex;
  align-items: center;
}
.data-area {
  height: 100px;
  flex: auto;
  /* border: solid 1px red; */
}
.power-areas {
  /* border: solid 1px red; */
  width: 300px;
  margin: 0;
  flex: none;
  background-color: #001f37;
  display: flex;
  flex-direction: column;
}
.station-content {
  width: 300px;
  flex: auto;
  /* border: solid 1px red; */
}
.float-btns {
  position: absolute;
  top: 160px;
  right: 0;
  width: 37px;
  height: 400px;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 200;
  background: #ffffff;
  box-shadow: 0px 4px 40px 0px rgba(56, 128, 252, 0.5);
  border-radius: 12px 0px 0px 12px;
  padding: 10px;
}
.btn-item {
  width: 36px;
  height: 102px;
  cursor: pointer;
}
.box-button-line {
  width: 20px;
  height: 1px;
  flex: none;
  background: #c0c5cb;
}
.box-button-item {
  flex: none;
  width: 20px;
  padding: 10px;
  color: #324252;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.box-button-item img {
  width: 14px;
  height: 14px;
}
.box-button-item span {
  width: 18px;
  margin: 5px 0 0 6px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
}
.box-button-active {
  color: #3880fc;
}
</style>