<template>
  <Modal v-model="showModal" :mask-closable="false" width="1300px">
    <div slot="header">{{showLang('lux.edit.lux.info')}}</div>
    <div class="config-lux-container">
      <div class="lux-data-area" ref="table">
        <table class="table-style" style="width: 100%" border="1" cellspacing="0">
          <tr>
            <td class="tr p5 nowrap">{{showLang('lux.Rule.Name')}}</td>
            <td style="padding: 2px;">
              <Input type="text" v-model="editItem.name">
              <!-- <span slot="prepend">属性名称</span> -->
              <!-- <span slot="append">秒</span> -->
              </Input>
            </td>
            <td class="remark">
            </td>
          </tr>
          <tr>
            <td class="tr p5 nowrap" style="width: 100px;">{{showLang('lux.data.acquisition.period')}}</td>
            <td style="width: 1010px;">
              <table style="width: 100%">
                <tr v-for="(int, idx) in editItem.intervals" :key="idx">
                  <td>
                    <Input type="number" v-model="int.val" number>
                    <span slot="prepend">{{showLang('lux.less.equal')}}</span>
                    <span slot="append">h</span>
                    </Input>
                  </td>
                  <td>
                    <Input type="number" v-model="int.interval" number>
                    <span slot="prepend">{{showLang('lux.acquisition.period')}}</span>
                    <span slot="append">s</span>
                    </Input>
                  </td>
                  <td style="display: flex;height: 38px;align-items: center;padding-left: 5px;">
                    <Button type="info" @click="add(idx+1)" style="margin-right:5px">+</Button>
                    <Button type="error" @click="del(idx)">-</Button>
                  </td>
                </tr>
                <tr>
                  <td class="nowrap tr p5">
                    {{showLang('lux.default.acquisition.period')}}
                  </td>
                  <td>
                    <Input type="number" v-model="editItem.interval" number>
                    <span slot="prepend">{{showLang('lux.acquisition.period')}}</span>
                    <span slot="append">s</span>
                    </Input>
                  </td>
                  <td style="padding-left: 5px;">
                    <Button type="info" @click="add(0)">+</Button>
                  </td>
                </tr>
              </table>
            </td>
            <td class="remark">
              {{showLang('lux.edit.lux.tell1')}}
            </td>
          </tr>
          <tr>
            <td class="tr p5 nowrap">{{showLang('lux.trigger.is.duration')}}</td>
            <td style="padding: 2px;">
              <Input type="number" v-model="editItem.confirmTime" number>
              <!-- <span slot="prepend">属性名称</span> -->
              <span slot="append">s</span>
              </Input>
            </td>
            <td class="remark">
              {{showLang('lux.edit.lux.tell2')}}
            </td>
          </tr>
          <tr>
            <td class="tr p5 nowrap">{{showLang('lux.distributing.ch.automatic')}}</td>
            <td>
              <table style="width: 100%">
                <tr>
                  <td style="width: 150px;" class="tr p5 nowrap">{{showLang('com.is.enable')}}</td>
                  <td style="padding: 3px">
                    <i-switch v-model="editItem.enableAuto" size="large" true-color="#13ce66" false-color="#ff4949"
                      style="width: 55px;flex:none;margin-right: 10px;">
                      <span slot="open">{{showLang('com.state.enable')}}</span>
                      <span slot="close">{{showLang('com.state.disable')}}</span>
                    </i-switch>
                  </td>
                </tr>
                <tr>
                  <td class="tr p5 nowrap">{{showLang('lux.ch.delay.time')}}</td>
                  <td>
                    <Input type="number" v-model="editItem.controlTime" number>
                    
                    <!-- <span slot="prepend">属性名称</span> -->
                    <span slot="append">min</span>
                    </Input>
                  </td>
                </tr>
                <tr>
                  <td class="tr p5 nowrap">{{showLang('lux.on.lamp.val')}}</td>
                  <td>
                    <Input type="number" v-model="editItem.openRate" number>
                    <!-- <span slot="prepend">属性名称</span> -->
                    
                    <span slot="append">lux</span>
                    </Input>
                  </td>
                </tr>
                <tr>
                  <td class="tr p5 nowrap">{{showLang('lux.advance.on.lamp.max')}}</td>
                  <td>
                    <Input type="number" v-model="editItem.openMaxTime" number>
                    <!-- <span slot="prepend">属性名称</span> -->
                    <span slot="append">min</span>
                    </Input>
                  </td>
                </tr>
                <tr>
                  <td class="tr p5 nowrap">{{showLang('lux.advance.on.lamp.min')}}</td>
                  <td>
                    <Input type="number" v-model="editItem.openMinTime" number>
                    <!-- <span slot="prepend">属性名称</span> -->
                    <span slot="append">min</span>
                    </Input>
                  </td>
                </tr>
                <tr>
                  <td class="tr p5 nowrap">{{showLang('lux.Delay.off.time')}}</td>
                  <td>
                    <Input type="number" v-model="editItem.closeMinTime" number>
                    <!-- <span slot="prepend">属性名称</span> -->
                    <span slot="append">min</span>
                    </Input>
                  </td>
                </tr>
                <tr>
                  <td class="tr p5 nowrap">{{showLang('lux.timeOut.trigger.action')}}</td>
                  <td style="padding: 3px">
                    <RadioGroup v-model="editItem.powerOtherAction">
                      <Radio :label="0">{{showLang('lux.powerOtherAction0')}}</Radio>
                      <Radio :label="1">{{showLang('lux.powerOtherAction1')}}</Radio>
                      <Radio :label="2">{{showLang('lux.powerOtherAction2')}}</Radio>
                    </RadioGroup>
                  </td>
                </tr>
                <tr>
                  <td class="tr p5 nowrap">{{showLang('lux.is.retransmit.interval')}}</td>
                  <td style="padding: 3px">
                    <Input type="number" v-model="editItem.resendCmdTime">
                    <!-- <span slot="prepend">属性名称</span> -->
                    <span slot="append">min</span>
                    </Input>
                  </td>
                </tr>
              </table>
            </td>
            <td class="remark">
              {{showLang('lux.edit.lux.tell3')}}
            </td>
          </tr>
          <tr>
            <td class="tr p5 nowrap">{{showLang('lux.lamp.Dimming.control')}}</td>
            <td>
              <table style="width: 100%">
                <tr>
                  <td style="width: 150px;" class="tr p5 nowrap">{{showLang('com.is.enable')}}</td>
                  <td style="padding: 3px">
                    <i-switch v-model="editItem.enableLight" size="large" true-color="#13ce66" false-color="#ff4949"
                      style="width: 55px;flex:none;margin-right: 10px;">
                      <span slot="open">{{showLang('com.state.enable')}}</span>
                      <span slot="close">{{showLang('com.state.disable')}}</span>
                    </i-switch>
                  </td>
                </tr>
                <tr>
                  <td class="tr p5 nowrap">{{showLang('lux.lamp.control.delayed')}}</td>
                  <td>
                    <Input type="number" v-model="editItem.controlLightTime" number>
                    <!-- <span slot="prepend">属性名称</span> -->
                    <span slot="append">min</span>
                    </Input>
                  </td>
                </tr>
                <tr>
                  <td class="tr p5 nowrap">{{showLang('lux.is.retransmit.interval')}}</td>
                  <td style="padding: 3px">
                    <Input type="number" v-model="editItem.resendLightTime" number>
                    <!-- <span slot="prepend">属性名称</span> -->
                    <span slot="append">min</span>
                    </Input>
                  </td>
                </tr>
                <tr>
                  <td class="tr p5 nowrap">{{showLang('lux.lamp.bright.color')}}</td>
                  <td>
                    <Button type="success" @click="addBright" long class="mb-5">{{showLang('lux.add.lamp.bright.color')}}</Button>
                    <table style="width: 100%" border="0">
                      <tr v-for="(bri, idx) in editItem.brights" :key="idx">
                        <td style="width: 40px">
                          {{idx+1}}、
                        </td>
                        <td style="width: 100px">
                          <Input type="number" v-model="bri.start" number>
                          <!-- <span slot="prepend">大于</span> -->
                          <span slot="append">{{showLang('com.date.to')}}</span>
                          </Input>
                        </td>
                        <td style="width: 100px">
                          <Input type="number" v-model="bri.val" number>
                          <!-- <span slot="prepend">小于</span> -->
                          <span slot="append">h</span>
                          </Input>
                        </td>
                        <td style="width: 60px">
                          <Checkbox v-model="bri.enable">{{showLang('com.state.enable')}}</Checkbox>
                        </td>
                        <td style="width: 150px">
                          <Input type="number" v-model="bri.bright" number>
                          <span slot="prepend">{{showLang('com.lighting.op')}}</span>
                          <span slot="append">%</span>
                          </Input>
                        </td>
                        <td style="display: flex;align-items:center;" class="nowarp">
                          {{showLang('lux.Cool.color')}}
                          <Slider v-model="bri.color" style="width:140px;margin: 0 10px;"></Slider>
                          <Input type="number" v-model="bri.color" number style="width:60px;margin: 0 10px;"></Input>
                          {{showLang('lux.warm.color')}}
                        </td>
                        <td style="width:100px">
                          <Button type="info" @click="insertBright(idx+1)" style="margin-right:5px">+</Button>
                          <Button type="error" @click="delBright(idx)">-</Button>
                        </td>
                      </tr>
                      <!-- <tr>
                      <td>{{editItem.brights}}</td>
                    </tr> -->
                    </table>
                  </td>
                </tr>
              </table>
            </td>
            <td class="remark">
              {{showLang('lux.edit.lux.tell4')}}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">{{showLang('com.op.cancel')}}</Button>
      <Button type="primary" :loading="loading" @click="ok">{{showLang('com.op.save')}}</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ModalRuleEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      tabHeight: 300,
      currentStationId: 0,
      showEditModal: false,
      loading: false,
      isAdd: false,
      editItem: {
        id: 0,
        name: '',
        enableAuto: false,
        enableLight: false,
        controlTime: 120,
        openRate: 100,
        openMinTime: 30,
        openMaxTime: 5,
        closeMinTime: 30,
        confirmTime: 30,
        powerOtherAction: 0,
        interval: 60,
        intervals: [],
        brights: [
          { start: 0, val: 100, enable: true, bright: 100, color: 100 }
        ],
        controlLightTime: 100,
        resendCmdTime: 3,
        resendLightTime: 3,
      },
    }
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    value(newVal) {
      this.showModal = newVal;
      if (this.value) {
        // console.log('open modal', this.item)
        if (this.item.isAdd) {
          this.editItem.id = 0;
          this.editItem.name = '';
          this.editItem.enableAuto = false;
          this.editItem.enableLight = false;
          this.editItem.controlTime = 5;
          this.editItem.openRate = 100;
          this.editItem.openMinTime = 30;
          this.editItem.openMaxTime = 5;
          this.editItem.closeMinTime = 30;
          this.editItem.confirmTime = 30;
          this.editItem.powerOtherAction = 0;
          this.editItem.interval = 60;
          this.editItem.intervals = [{ val: 100, interval: 30 }];
          this.editItem.brights = [{ start: 0, val: 100, enable: true, bright: 100, color: 100 }];
          this.editItem.controlLightTime = 5;
          this.editItem.resendCmdTime = 3;
          this.editItem.resendLightTime = 3;
        } else {
          this.editItem.id = this.item.data.id;
          this.editItem.name = this.item.data.name;
          this.editItem.enableAuto = this.item.data.enableAuto;
          this.editItem.enableLight = this.item.data.enableLight;
          this.editItem.controlTime = this.item.data.controlTime;
          this.editItem.openRate = this.item.data.openRate;
          this.editItem.openMinTime = this.item.data.openMinTime;
          this.editItem.openMaxTime = this.item.data.openMaxTime;
          this.editItem.closeMinTime = this.item.data.closeMinTime;
          this.editItem.confirmTime = this.item.data.confirmTime;
          this.editItem.powerOtherAction = this.item.data.powerOtherAction;
          this.editItem.interval = this.item.data.interval;
          this.editItem.intervals = this.item.data.intervals;
          this.editItem.brights = this.item.data.brights;
          this.editItem.controlLightTime = this.item.data.controlLightTime;
          this.editItem.resendCmdTime = this.item.data.resendCmdTime;
          this.editItem.resendLightTime = this.item.data.resendLightTime;
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function () {
    // this.getLuxRule();
  },
  methods: {
    addBright: function () {
      this.editItem.brights.push({ start: 0, val: 100, enable: true, bright: 100, color: 100 });
    },
    insertBright: function (idx) {
      // console.log('add lux rule idx', idx)
      if (idx <= 0) {
        this.$set(this.editItem.brights, this.editItem.brights.length, { start: 0, val: 100, enable: true, bright: 100, color: 100 });
      } else {
        this.editItem.brights.splice(idx - 1, 0, { start: 0, val: 100, enable: true, bright: 100, color: 100 });
      }
    },
    delBright: function (idx) {
      if (idx < 0) return;
      this.editItem.brights.splice(idx, 1);
    },
    del: function (idx) {
      if (idx < 0) return;
      this.editItem.intervals.splice(idx, 1);
    },
    add: function (idx) {
      if (idx <= 0) {
        this.$set(this.editItem.intervals, this.editItem.intervals.length, { val: 100, interval: 30 });
      } else {
        this.editItem.intervals.splice(idx - 1, 0, { val: 101, interval: 60 });
      }
    },
    ok: function () {
      // console.log('save lux rule', this.editItem)
      this.$axios.post(`//${this.domains.trans}/station/lux/SaveRule`, this.editItem).then(res => {
        if (res.code === 0) {
          this.$Message.info(this.showLang('com.tips.save'))
          this.showModal = false;
          this.$emit('saved');
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight;
    },
    setDefaultStaion: function () {
      if (this.selectedStations.length > 0) {
        this.currentStationId = this.selectedStations[0].station.id;
      }
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.remark {
}
.table-style,
.table-style td {
  border: solid 1px rgba(7, 156, 156, 0.356);
}
/* .table-style td{
  padding: 5px 10px;
} */
.config-lux-container {
  /* padding: 5px;
  padding-right: 50px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  /* border: solid 1px red; */
}
.lux-search-area {
  height: 42px;
  flex: none;
  border: solid 1px #dcdee2;
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 3px 10px;
  display: flex;
  align-items: center;
}
.lux-data-area {
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  border: solid 1px #dcdee2;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: auto;
}
.lux-data-item {
  width: 100%;
  height: 200px;
  flex: none;
  /* border: solid 1px red; */
  border: solid 1px #cad1d9;
  margin-bottom: 30px;
  border-radius: 6px;
}
.lux-item-header {
  display: flex;
  height: 48px;
  align-items: center;
  background: linear-gradient(270deg, #3682ff, #4c9eff);
  border-radius: 6px 6px 0px 0px;
}

.header-title {
  line-height: 48px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  flex: none;
  /* width: 74px; */
  display: flex;
  align-items: center;
}
.header-title div {
  width: 6px;
  height: 6px;
  background: #ffffff;
  margin: 0 10px 0 20px;
}
.header-extra {
  cursor: pointer;
  width: 74px;
  height: 22px;
  border: 1px solid #1b5fa8;
  border-radius: 4px;
  margin-right: 20px;
  flex: none;
  text-align: center;
  background-color: #ffffff;
  color: #3880fc;
}
.header-content {
  flex: auto;
}
.edit-content {
  padding: 20px;
}
</style>