<template>
<div class="config-station-container">
  <div class="station-data-area" ref="table">
    <u-table :data="list" style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all :empty-text="showLang('com.data.no')">
      <u-table-column prop="name" :label="showLang('com.tab.title')" sortable width="200">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <!-- <u-table-column prop="channels" :label="showLang('lux.Control.num')" sortable width="130">
        <template slot-scope="params">
          {{params.row.channels.length}}
        </template>
      </u-table-column>
      <u-table-column prop="channels" :label="showLang('lux.lights.num')" sortable width="130">
        <template slot-scope="params">
          {{params.row.lights.length}}
        </template>
      </u-table-column>
      <u-table-column prop="remark" :label="showLang('lux.Group.illustrate')" sortable>
        <template slot-scope="params">
          {{params.row.remark}}
        </template>
      </u-table-column> -->
      <u-table-column width="230">
        <template slot="header" slot-scope="scope">
          <AuthButton opCode='lga1' style="margin-right: 5px" @click="handleAdd(scope)">{{showLang('com.op.add')}}</AuthButton>
          <AuthButton opCode='lgv1' style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='lge1' type="success" style="margin-right: 5px" @click="handleChannel(scope)">{{showLang('com.ins.ch')}}</AuthButton>
          <!-- <AuthButton opCode='lge1' type="success" style="margin-right: 5px" @click="handleLight(scope)">{{showLang('lux.lamp')}}</AuthButton> -->
          <AuthButton opCode='lge1' style="margin-right: 5px" @click="handleEdit(scope)">{{showLang('com.op.edit')}}</AuthButton>
          <AuthButton opCode='lgd1' type="error" @click="handleDelete(scope)">{{showLang('com.op.del')}}</AuthButton>
        </template>
      </u-table-column>
    </u-table>
  </div>
  <ModalGroupEdit v-model="showEditModal" :item="editItem" @saved="getList" />
  <ModalChannelEdit v-model="showChannelModal" :item="editItem" @saved="getList" />
  <!-- <ModalLightEdit v-model="showLightModal" :item="editItem" @saved="getList" /> -->
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalGroupEdit from './ModalGroupEdit'
import ModalChannelEdit from './ModalChannelEdit'
// import ModalLightEdit from './ModalLightEdit'
export default {
  name: 'LuxConfigGroup',
  components:{
    ModalGroupEdit,
    ModalChannelEdit,
    // ModalLightEdit,
  },
  data () {
    return {
      showEditModal: false,
      showChannelModal: false,
      showLightModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      list: [],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize(){
      this.tabHeight = this.$refs.table.clientHeight
      // console.log('windowOnResize', this.windowOnResize, this.$refs.table.clientHeight)
    },
  },
  mounted: function(){
    setTimeout(() => {
      this.tabHeight = this.$refs.table.clientHeight;
    }, 200);
    this.getList();
  },
  methods: {
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryOutputGroup`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          res.data.map(p => {
            if(!p.lights){
              p.lights = [];
            }
            if(!p.channels){
              p.channels = [];
            }
          })
          this.$set(this, 'list', res.data);
        }
      });
    },
    getDate:function(date){
      let d = new Date(date);
      if(d.getFullYear() == 1970)return '';
      return d.format('yyyy-MM-dd');
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    handleChannel: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showChannelModal = true;
    },
    handleLight: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showLightModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content:this.showLang('com.op.prompt',params.row.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/lux/DeleteOutputGroup`, {id: params.row.id}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    handleFile: function(){
      this.$Message.warning('暂未实现');
    },
    handleImport: function(){
      this.$Message.warning('暂未实现');
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
}
.station-search-area{
  height: 42px;
  flex: none;
  border: solid 1px #DCDEE2;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-top: 3px;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>