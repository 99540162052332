<template>
<div class="config-station-container">
  <div class="station-data-area" ref="table">
    <u-table :data="list" style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all :empty-text="showLang('com.data.no')">
      <u-table-column prop="name" :label="showLang('com.tab.title')" sortable width="200" >
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="interval" :label="showLang('lux.gather.interval')" sortable width="130">
        <template slot-scope="params">
          {{params.row.interval}} s
        </template>
      </u-table-column>
      <u-table-column prop="confirmTime" :label="showLang('lux.trigger.ok.duration')" sortable width="130">
        <template slot-scope="params">
          {{params.row.confirmTime}} s
        </template>
      </u-table-column>
      <u-table-column prop="enableAuto" :label="showLang('lux.is.Enable.control')" sortable width="160">
        <template slot-scope="params">
          {{params.row.enableAuto ? showLang('com.state.enable') : showLang('com.state.disable')}}
        </template>
      </u-table-column>
      <u-table-column prop="controlTime" :label="showLang('lux.control.delayed')" sortable width="160">
        <template slot-scope="params">
          {{params.row.controlTime}} min
        </template>
      </u-table-column>
      <u-table-column prop="openRate" :label="showLang('lux.Lighting.limit')" sortable width="160">
        <template slot-scope="params">
          {{params.row.openRate}} lux
        </template>
      </u-table-column>
      <u-table-column prop="openMaxTime" :label="showLang('lux.limit.light.max')" sortable width="200">
        <template slot-scope="params">
          {{params.row.openMaxTime}} min
        </template>
      </u-table-column>
      <u-table-column prop="openMinTime" :label="showLang('lux.limit.light.min')" sortable width="200">
        <template slot-scope="params">
          {{params.row.openMinTime}} min
        </template>
      </u-table-column>
      <u-table-column prop="closeMinTime" :label="showLang('lux.Delay.off.time')" sortable width="160">
        <template slot-scope="params">
          {{params.row.closeMinTime}} min
        </template>
      </u-table-column>
      <u-table-column prop="powerOtherAction" :label="showLang('lux.timeOut.trigger.action')" sortable width="160">
        <template slot-scope="params">
          {{actions[params.row.powerOtherAction]}}
        </template>
      </u-table-column>
      <u-table-column prop="enableLight" :label="showLang('lux.is.light.control')" sortable width="160">
        <template slot-scope="params">
          {{params.row.enableLight ? showLang('com.state.enable') : showLang('com.state.disable')}}
        </template>
      </u-table-column>
      <u-table-column prop="controlLightTime" :label="showLang('lux.lamp.control.delayed')" sortable width="160">
        <template slot-scope="params">
          {{params.row.controlLightTime}} min
        </template>
      </u-table-column>
      <u-table-column width="135" fixed="right">
        <template slot="header" slot-scope="scope">
          <AuthButton opCode='lra' style="margin-right: 5px" @click="handleAdd(scope)">{{showLang('com.op.add')}}</AuthButton>
          <AuthButton opCode='lrv' style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='lre' style="margin-right: 5px" @click="handleEdit(scope)">{{showLang('com.op.edit')}}</AuthButton>
          <AuthButton opCode='lrd' type="error" @click="handleDelete(scope)">{{showLang('com.op.del')}}</AuthButton>
        </template>
      </u-table-column>
    </u-table>
  </div>
  <ModalRuleEdit v-model="showEditModal" :item="editItem" @saved="getList" />
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalRuleEdit from './ModalRuleEdit'
export default {
  name: 'LuxConfigGroup',
  components:{
    ModalRuleEdit,
  },
  data () {
    return {
      showEditModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      list: [],
      actions: {
        0: '不动作',
        1: '界面提示开灯',
        2: '自动控制开灯',
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize(){
      this.tabHeight = this.$refs.table.clientHeight
      // console.log('windowOnResize', this.windowOnResize, this.$refs.table.clientHeight)
    },
  },
  mounted: function(){
    setTimeout(() => {
      this.tabHeight = this.$refs.table.clientHeight;
    }, 200);
    this.getList();
    this.actions[0]=this.showLang('lux.powerOtherAction0')
    this.actions[1]=this.showLang('lux.powerOtherAction1')
    this.actions[2]=this.showLang('lux.powerOtherAction2')
  },
  methods: {
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryRule`, {}).then(res => {
        if(res.code === 0){
          this.$set(this, 'list', res.data)
        }
      });
    },
    getDate:function(date){
      let d = new Date(date);
      if(d.getFullYear() == 1970)return '';
      return d.format('yyyy-MM-dd');
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content:this.showLang('lux.is.del.Control.rules',params.row.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/lux/DeleteRule`, {id: params.row.id}).then(res => {
            if(res.code == 0){
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
}
.station-search-area{
  height: 42px;
  flex: none;
  border: solid 1px #DCDEE2;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-top: 3px;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>