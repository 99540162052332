<template>
  <div class="ModalControlGroup-box">
    <div class="top-search">
      <Form ref="form" :model="form" :label-width="90" inline>
        <FormItem prop="location" :label="showLang('lux.delay.time')">
          <DatePicker type="datetime" v-model="form.expire" style="width: 175px" format="yyyy-MM-dd HH:mm:ss">
          </DatePicker>
        </FormItem>
        <FormItem :label-width="0">
          <AuthButton opCode="occ" size="default" type="error" :loading="loading" @click="handleOpen"
            style="margin-right: 10px">{{showLang('lux.one.on.lights')}}</AuthButton>
          <AuthButton opCode="occ" size="default" type="default" :loading="loading" @click="handleClose">
            {{showLang('lux.one.off.lights')}}</AuthButton>
        </FormItem>
      </Form>
    </div>
    <u-table ref="chkTable" :data="devices" style="width: 100%;" row-key="id" border stripe :height="500" :empty-text="showLang('com.data.no')"
      default-expand-all>
      <u-table-column prop="id" type="index">
      </u-table-column>
      <u-table-column prop="channelName" :label="showLang('com.ins.ch.name')" sortable width="120">
        <template slot-scope="params">
          {{params.row.channelName}}
        </template>
      </u-table-column>
      <u-table-column prop="stationName" :label="showLang('com.site.name')" sortable width="150">
        <template slot-scope="params">
          {{params.row.stationName}}
        </template>
      </u-table-column>
      <u-table-column prop="channel" :label="showLang('com.ins.ch.order')" sortable width="100">
        <template slot-scope="params">
          K{{params.row.channel}}
        </template>
      </u-table-column>
      <u-table-column prop="timeName" :label="showLang('com.ins.timeTable')" sortable width="150">
        <template slot-scope="params">
          {{params.row.timeName}}
        </template>
      </u-table-column>
      <u-table-column prop="result" :label="showLang('com.ins.result')" sortable>
        <template slot-scope="params">
          {{getCmdStatus(params.row)}}
        </template>
      </u-table-column>
    </u-table>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalControlGroup',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    itemData: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      loading: false,
      form: {
        open: false,
        expire: '',
      },
      devices: [],
    }
  },
  watch: {
    itemData() {
      this.form.open = true;
      let now = new Date();
      now.setHours(now.getHours() + 1);
      this.form.expire = now.format('yyyy-MM-dd HH:mm:ss');
      this.initList();
    },
    cmdResultRefresh() {
      for (let c of this.commands) {
        let its = this.devices.filter(p => p.id == c.id);
        if (its.length == 0) continue;
        this.$set(its[0], 'status', c.status)
        this.$set(its[0], 'result', c.result)
        this.$set(its[0], 'cmd', c.code)
        this.$set(its[0], 'content', c.content)
        this.$set(its[0], 'args', c.args)
      }
    },
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  mounted: function () {
    this.form.open = true;
    let now = new Date();
    now.setHours(now.getHours() + 1);
    this.form.expire = now.format('yyyy-MM-dd HH:mm:ss');
    this.initList();
  },
  methods: {
    handleAction: function (open) {
      let expire = new Date(this.form.expire).format('yyyy-MM-dd HH:mm:ss');
      let cmds = [];
      for (let chk of this.devices) {
        let args = { channel: chk.channel, open: open, point: false, expire: expire };
        chk.result = '';
        chk.status = 0;
        cmds.push({ id: chk.id, code: 'handControl', name: "一键控制", paramId: chk.id, deviceId: chk.deviceId, args: args });
      }
      this.$store.dispatch('cmd/sendCommands', cmds);
    },
    handleOpen: function () {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('lux.ok.on.lights'),
        okText: this.showLang('com.op.ok'),
        cancelText: this.showLang('com.op.cancel'),
        onOk: async () => {
          this.handleAction(true);
        }
      });
    },
    handleClose: function () {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('lux.no.off.lights'),
        okText: this.showLang('com.op.ok'),
        cancelText: this.showLang('com.op.cancel'),
        onOk: async () => {
          this.handleAction(false);
        }
      });
    },
    getCmdStatus: function (cmd) {
      // console.log('cmd status changed', cmd)
      switch (cmd.status) {
        case 0: return `${cmd.result}`;
        case 1: return this.showLang('com.ins.wait');
        case 2: return this.showLang('com.ins.timeout');
        case 3: return this.showLang('com.ins.fail')+`：${cmd.result}`;
        case 4: return this.showLang('com.ins.execution')+`：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return this.showLang('com.ins.fail')+`：${cmd.result}`;
        case 9:
          switch (cmd.cmd) {
            case 'getclock': return this.showLang('com.ins.success')+`：${cmd.content.nowTime}`;
            case 'handControl': return this.showLang('com.ins.success')+`：${cmd.args.open ? this.showLang('lux.on.lights') : this.showLang('lux.off.lights')+this.showLang('lux.to.time')+cmd.args.expire}`;
            case 'getYearPlan': {
              if (cmd.args.month != cmd.content.month) {
                return `${this.showLang('com.ins.success.WrongMonth', cmd.args.month)}${cmd.content.month}`;
              } else {
                let strs = [];
                let obj = cmd.content[`day${cmd.args.day}`];
                for (let i = 1; i <= 4; i++) {
                  let a = this.powerTableActions[obj[`a${i}`]];
                  let t = obj[`t${i}`];
                  strs.push(`${t} - ${a}`);
                }
                return this.showLang('com.ins.success')+`：${cmd.args.month+ this.showLang('com.date.month')+cmd.args.day+this.showLang('com.date.sun')+this.showLang('com.date.plan')}：${strs.join(', ')}`
              }
            }
            default: return this.showLang('com.ins.success');

          }
      }
    },
    initList: function () {
      // console.log('init list', this.item);
      if (!this.item.id) return;
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryChannels`, { id: this.item.id }).then(res => {
        if (res.code == 0) {
          res.data.map(p => {
            Object.assign(p, { id: p.paramId, time: 0, status: 0, result: '' })
          })
          this.$set(this, 'devices', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.ModalControlGroup-box {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.form-area {
  /* border: solid 1px red; */
  height: 500px;
  display: flex;
}
.edit-area {
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 250px;
  flex: none;
  margin-right: 10px;
  padding: 10px;
}
.channel-area {
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 290px;
  flex: auto;
}
</style>