<template>
  <div class="config-station-container">
    <div class="station-data-area" ref="table">
      <u-table :data="list" style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all :empty-text="showLang('com.data.no')">
        <u-table-column prop="name" :label="showLang('com.tab.title')" sortable width="200" fixed="left"></u-table-column>
        <u-table-column prop="code" label="通信ID" sortable width="150" fixed="left"></u-table-column>
        <u-table-column prop="ruleName" label="应用规则" sortable width="200"></u-table-column>
        <u-table-column prop="transTypeName" label="通信设备类型" sortable width="200"></u-table-column>
        <u-table-column prop="smallTypeName" label="采集设备类型" sortable width="200"></u-table-column>
        <u-table-column prop="count" label="采集设备数量" width="110"></u-table-column>
        <u-table-column prop="avgDiff" :label="showLang('lux.rate.need')" width="110"></u-table-column>
        <u-table-column prop="simCard" label="SIM卡号" sortable width="150"></u-table-column>
        <u-table-column prop="buildDate" :label="showLang('com.date.installation')" width="100">
          <template slot-scope="params">
            {{new Date(params.row.buildDate).format('yyyy-MM-dd')}}
          </template>
        </u-table-column>
        <u-table-column prop="location" :label="showLang('com.Installation.position')" sortable width="300"></u-table-column>
        <u-table-column prop="outputGroups" label="控制分组列表" width="300">
          <template slot-scope="params">
            {{params.row.outputGroups.map(p => p.name).join(',')}}
          </template>
        </u-table-column>
        <u-table-column prop="lightGroups" label="单灯分组列表" width="300">
          <template slot-scope="params">
            {{params.row.lightGroups.map(p => p.name).join(',')}}
          </template>
        </u-table-column>
        <u-table-column width="130" fixed="right">
          <template slot="header" slot-scope="scope">
            <AuthButton opCode='lsa' style="margin-right: 5px" @click="handleAdd(scope)">{{showLang('com.op.add')}}
            </AuthButton>
            <AuthButton opCode='lsv' style="margin-right: 5px" @click="getList">{{showLang('com.op.refresh')}}
            </AuthButton>
          </template>
          <template slot-scope="scope">
            <AuthButton opCode='lse' style="margin-right: 5px" @click="handleEdit(scope)">{{showLang('com.op.edit')}}
            </AuthButton>
            <AuthButton opCode='lsd' type="error" @click="handleDelete(scope)">{{showLang('com.op.del')}}</AuthButton>
          </template>
        </u-table-column>
      </u-table>
    </div>
    <ModalStationEdit v-model="showEditModal" :item="editItem" :formArgs="formArgs" @saved="getList" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalStationEdit from './ModalStationEdit'
export default {
  name: 'ConfigStationIndex',
  components: {
    ModalStationEdit,
  },
  data() {
    return {
      showEditModal: false,
      tabHeight: 300,
      editItem: { isAdd: true, index: -1, data: {} },
      list: [],
      formArgs: {
        outputGroups: [],
        lightGroups: [],
        rules: [],
        smallTypes: [],
        transTypes: [],
      },
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['showLang']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize() {
      this.tabHeight = this.$refs.table.clientHeight
      // console.log('windowOnResize', this.windowOnResize, this.$refs.table.clientHeight)
    },
  },
  mounted: function () {
    setTimeout(() => {
      this.tabHeight = this.$refs.table.clientHeight;
    }, 200);
    this.getRules();
    this.getOutputGroups();
    this.getLightGroups();
    this.getEnvDeviceTypes();
    this.getTransDeviceTypes();
    this.getList();
  },
  methods: {
    getGroupName: function (id) {
      let grps = this.groups.filter(p => p.id == id);
      if (grps.length == 0) return '';
      return grps[0].name;
    },
    getEnvDeviceTypes: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/GetEnvDeviceTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.formArgs, 'smallTypes', res.data);
        }
      });
    },
    getTransDeviceTypes: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/GetTransDeviceTypes`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.formArgs, 'transTypes', res.data);
        }
      });
    },
    getList: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryDevice`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
        }
      });
    },
    getRules: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryRule`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.formArgs, 'rules', res.data);
        }
      });
    },
    getOutputGroups: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryOutputGroup`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.formArgs, 'outputGroups', res.data);
        }
      });
    },
    getLightGroups: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryLightGroup`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this.formArgs, 'lightGroups', res.data);
        }
      });
    },
    getDate: function (date) {
      let d = new Date(date);
      if (d.getFullYear() == 1970) return '';
      return d.format('yyyy-MM-dd');
    },
    handleAdd: function () {
      this.editItem = { isAdd: true, data: {} };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row };
      this.showEditModal = true;
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: this.showLang('com.op.prompt'),
        content: this.showLang('com.tips.ok.del.name', params.row.name),
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/lux/DeleteDevice`, {id: params.row.id}).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.config-station-container {
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
}
.station-search-area {
  height: 42px;
  flex: none;
  border: solid 1px #dcdee2;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-top: 3px;
}
.station-data-area {
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>